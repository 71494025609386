import * as React from 'react'
import { Layout } from '../components/layout'
import { PageHeader } from '../components/pageHeader'
import { DeviceOffline } from '../components/deviceOffline'

const PrinterOfflinePage = () => {
  return (
    <Layout>
      <PageHeader>Cryo Pi offline</PageHeader>
      <div>
        <DeviceOffline>
          Der Cryo Pi ist nicht erreichbar. Höchstwahrscheinlich weil er nicht
          angeschaltet ist. Am besten mal runter ins Labor gehen.
        </DeviceOffline>
      </div>
    </Layout>
  )
}

export default PrinterOfflinePage
